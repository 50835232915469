.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.activeDrag {
  cursor: grab;
}

.activeDrag:active {
  cursor: grabbing;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  backface-visibility: hidden;
  visibility: hidden;
}

.item[style] {
  visibility: visible;
}

.debug {
  overflow: visible;
  background: lightgray;
}

.debug .item {
  color: black;
  background: red;
  border-radius: 50%;
}